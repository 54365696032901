import AppRouter from './routes';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import Loader from '../src/components/Loader/Spinner';
import { useEffect } from 'react';

const theme = createTheme({
  typography: {
    fontFamily: '"Work Sans", sans-serif',
  },
  
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlined: {
          borderColor: '#504fe9',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#504fe9', // Set your desired focus outline color here
            },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#504fe9', // Replace 'gray' with your desired default color value
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.Mui-focused .MuiAutocomplete-input': {
            borderColor: '#504fe9', // Set your desired focus outline color here
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#504fe9', // Replace 'gray' with your desired default color value
          },
        },
      },
    },
  },
});
console.log('env', process.env.NODE_ENV);
console.log('env', process.env.REACT_APP_BASE_URL);

function useDynamicScrollBehavior() {
  const location = useLocation();

  useEffect(() => {
    const rootElement = document.documentElement;

    const disableScroll = () => {
      rootElement.style.overflow = 'hidden';  // Disable scrolling
      rootElement.style.scrollBehavior = 'auto'; // Disable smooth scrolling
    };

     // Function to enable smooth scrolling and overflow
     const enableScroll = () => {
      rootElement.style.overflow = ''; // Enable scrolling
      rootElement.style.scrollBehavior = 'smooth'; // Enable smooth scrolling
    };


    // List of paths where scroll should be disabled
    const pathsToDisableScroll = [
      '/create-rfi/customize-template',
      '/supplier/rfi', 
      '/view-rfi',
    ];

    // Check if the current location.pathname starts with any of the paths in pathsToDisableScroll
    const isMatchingRoute = pathsToDisableScroll.some(path => location.pathname.startsWith(path))

    if (isMatchingRoute) {
      disableScroll();
    } else {
      enableScroll();
    }

    return () => {
      enableScroll(); // Ensure scroll is enabled when component is unmounted or path changes
    };
  }, [location.pathname, location.search]);
}

function ScrollBehaviorProvider() {
  useDynamicScrollBehavior();
  return null;
}

export default function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollBehaviorProvider />
        <Loader />
        <AppRouter />
      </BrowserRouter>
    </ThemeProvider>
  );
}
