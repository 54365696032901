export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const VERSION = "/v1/";
export const LOGIN = "user/login";
export const SIGN_UP = "user";
export const VERIFY_BUISNESS_EMAIL = "user/verify-email";
export const FORGET_PASSWORD = "user/forgot-password";
export const CHANGE_PASSWORD = "user/reset-password";
export const CREATE_PASSWORD = "user/create-password";
export const RESET_PASSWORD = "user/confirm-reset";
export const INVITE_USERS = "user/invite";
export const REINVITE_USER = "user/re-invite";
export const GET_INVITED_USER_DETAILS = "user/user-details";
export const GET_USERS = "user";
export const UPDATE_INVITED_USER = "user/update-invite/";
export const SPEND_CUBE_BASE_URL = "http:localhost:3200";
export const GET_ALL_PROJECTS = "project";
export const SUPPLIER_PROJECTS = "supplier-projects";
export const GENERATE_PROJECT_ID = "project";
export const VERIFY_USER = "user/verify-user";
export const ORGANISATION_PROFILE_DETAILS = "user/organisation";
export const VERIFY_REFRESH_TOKEN = "user/refresh-token-verify";
export const RESEND_VERIFICATION_LINK = "user/resend-signup-verification";
export const GET_TEAM_MEMBERS = "user/team-member";
export const CREATE_PROJECT = "project/update";
export const GET_RFI_TEMPLATES = "master-rfi";
export const GET_TEMPLATE_BY_ID = "master-rfi";
export const CREATE_RFI = "rfi";
export const GET_SUPPLIERS = "user/suppliers";
export const GET_SUPPLIER = "supplier";
export const GET_SINGLE_SUPPLIER = "supplier";
export const INVITE_SUPPLIERS = "user/supplier/invite";
export const LAUNCH_RFI = "rfi/launch";
export const GET_PROJECT_DETAILS = "project/get-project";
export const UPDATE_RFI = "rfi/update";
export const SELLER_SIGNUP = "user/supplier/create";
export const GET_RFI_RESPONSES = "rfi/response";
export const FET_SUPPLIER_RFI = "rfi/supplier";
export const DELETE_PROJECT = "project/inactive";
export const NOTIFICATION = "notifications/";
export const NOTIFICATION_USER = "notifications/user/";
export const NOTIFICATION_POOL = "notifications/pooling/";
export const NOTIFICATION_READ_ALL = "notifications/read-all/";
export const NOTIFICATION_READ_SINGLE = "notifications/read/";
export const GET_SUPPLIER_PROJECT_DETAILS = "project/supplier";
export const UPDATE_SUPPLIER_PROJECT = "project/supplier-projects";
export const SAVE_SUPPLIER_RESPONSES = "rfi/response";
export const ACCEPT_OR_REJECT_PROJECT = "rfi/accept";
export const NOTIFICATION_UNREAD_SINGLE = "notifications/unread/";
export const AUTOFILL_ENDPOINT = "user/contactInfo";
export const GET_SHORTLISTED_SUPPLIERS = "rfi/shortlisted-suppliers";
export const SHORTLIST_SUPPLIERS = "rfi/shortList-suppliers";
export const UNSHORTLIST_SUPPLIERS = "rfi/unshortList-suppliers";
export const GET_SUPPLIER_RESPONSES = "project/rfi-response";
export const PROCEED_TO_RFQ = "rfi/proceed-rfq";
export const RFQ_TEMPLATE_LIST = "rfqtemplate";
export const SPENDCUBE_LIST_FOR_PROJECT = "getSpendCubeListForProject/";
export const SPENDCUBE_DATA_FOR_PROJECT = "getSpendCubeDataForProject/";
export const SPENDCUBE = "spendcube/";

export const ADD_SUPPLIERS_TO_RFI = "rfi/invite-supplier";
export const CONVERSATION_CHANNEL_LIST = "messenger/project/getchannel/";
export const CONVERSATION_SAVE = "messenger/save-conversation-attachment";
export const CONVERSATION_READ = "messenger/get-conversation/";
export const CONVERSATION_BADGE_COUNTS = "messenger/checknewmessage/";
export const CONVERSATION_ANNOUNCEMENT = "messenger/send-projectannouncement";
export const CONVERSATION_SEARCH = "messenger/project/searchchannel";
export const CONVERSATION_BADGE_GLOBEL_COUNTS =
  "messenger/get_global_channel_count/";

export const LAUNCH_RFQ = "rfq/launch";
export const GET_PROJECT_ROLE_DROPDOWN_DATA = "project/dropdown_data/active";
export const SAVE_APPROVAL_MATRIX = "project/add/approval_matrix";
export const GET_APPROVAL_MATRIX_DATA = "project/get/approval_matrix";
export const UPDATE_APPROVAL_MATRIX = "project/change/approval_matrix";
export const REQUEST_FOR_APPROVAL = "project/request/approval";
export const GET_APPROVAL_STATUS = "project/reviewDetails";
export const PROJECT_APPROVAL = "project/response/request/approval";
export const GET_APPROVAL_INBOX_DETAILS = "project/approval_matrix/inbox";

export const INVITE_NEW_USERS_TO_PROJECTS = "user/supplier/invite-after-launch";
export const UPDATE_RFQ_ANALYSER_DATA =
  "spendanalytics/updateMarketPlaceSupplier";
export const GEt_VERSION_DETAILS = "user/app/get_app_version";
